import React from "react";
import TabGuestLedger from "./TabGuestLedger";
type Props = {};

const TabGuestLedgerWrapper = (props: Props) => {
  return (
      <TabGuestLedger />
  );
};
export default TabGuestLedgerWrapper
