import { CircularProgress, Dialog } from "@mui/material";
import React, { useState } from "react";
import { BiFilter } from "react-icons/bi";
import { IoClose } from "react-icons/io5";
import TabPayementSummaryFilterCardWrapper from "./TabPayementSummaryFilterCard/TabPayementSummaryFilterCardWrapper";

type Props = {
  payemntGateway: any;
  RewardPayment:any ;
  RefundPayment:any ;
  isLoading: boolean;

};

const TabPaymentSummaryListing = ({ payemntGateway, RewardPayment ,RefundPayment , isLoading }: Props) => {
  const [isOpenFilter, setIsOpenFilter] = useState(false);
  return (
    <>
      <div className="pt-4 px-8">
        <div className="text-2xl font-semibold">Payment Summary</div>
        <div className="h-full relative mt-2 border-b p-2">
          <button
            type="button"
            onClick={() => setIsOpenFilter((prev) => !prev)}
            className="bg-primary-main text-white rounded flex gap-2 justify-center px-4 py-1 items-center text-sm font-medium h-full"
          >
            <BiFilter className="text-2xl" /> Filters
          </button>
          {isOpenFilter && (
            // <ClickAwayListener onClickAway={() => setIsOpenFilter(false)}>
            <Dialog open={true}>
              <div className="flex justify-end text-2xl text-slate-500 px-2 py-1">
                <button
                  type="button"
                  onClick={() => setIsOpenFilter(false)}
                  className="p-1 rounded-full flex justify-center items-center bg-slate-200 hover:bg-red-400 hover:text-white transition-all"
                >
                  <IoClose />
                </button>
              </div>
              <div className="px-5 pb-5">
                <div>
                  <TabPayementSummaryFilterCardWrapper
                    close={() => setIsOpenFilter(false)}
                  />{" "}
                </div>
              </div>
            </Dialog>
            // </ClickAwayListener>
          )}
        </div>
       {isLoading ? <div className="  flex justify-center items-end h-[150px]"><CircularProgress/> </div>  :  <div className="flex justify-between  gap-2 px-2 mt-2 items-center">
          <div className="flex gap-6 font-medium">
            <span>
              Total Reward :  ₹{RewardPayment || 0}
            </span>
            <span>
              Total Refund :  ₹{RefundPayment || 0}
            </span>
            <span>
              Total Payment Gateway :{" "}
              ₹{payemntGateway || 0}
            </span>
          </div>
        </div>}
      </div>
    </>
  );
};

export default TabPaymentSummaryListing;
