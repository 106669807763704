import React from "react";
import TabRetailerLedger from "./TabRetailerLedger";

type Props = {};

const TabRetailerLedgerWrapper = (props: Props) => {
  return (
      <TabRetailerLedger />
  );
};
export default TabRetailerLedgerWrapper
