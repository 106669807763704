import React, { useEffect, useState } from "react";
import SideNavLayout from "src/components/layouts/SideNavLayout/SideNavLayout";
import { DashbaordListResponse } from "src/models/Dashboard.model";
import { useGetApplicationInProgressStatusCountQuery, useGetApplicationStatusCountQuery, useGetDashboardDataQuery, useGetTotalUserCountQuery } from "src/services/DashboardServices";
import DashboardListing from "./DashboardListing";
import { useGetPendingApplicattionCountQuery } from "src/services/PendingApplicationService";

const DashboardListingWrapper = () => {

  const { data, isLoading, isFetching } = useGetDashboardDataQuery("");
  const [dashboardCountData, setDashboardCountData] = useState<DashbaordListResponse | null>(null);
  const [userCount, setUserCount] = useState<any>()
  const [pendingApplicationCount, setPendingApplicationCount] = React.useState<any>(null);
  const [applicationStatusCountData, setApplicationStatusCountData] = useState<any>(null);
  const [applicationInProgressCount , setApplicationInProgressCount] = useState<any>()

  const {
    data: pendingData,
    isLoading: pendngCountIsLoading,
    isFetching: pendngCountIsFetching,
  } = useGetPendingApplicattionCountQuery("");
  const { data: userData, isLoading: userDataIsLoading, isFetching: userDataIsFetching } = useGetTotalUserCountQuery('')

  React.useEffect(() => {
    if (!pendngCountIsLoading && !pendngCountIsFetching) {
      setPendingApplicationCount(pendingData?.data || null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendngCountIsLoading, pendngCountIsFetching, pendingData]);

  useEffect(() => {
    if (!isFetching && !isLoading) {
      setDashboardCountData(data?.data || null);
    }
  }, [isLoading, isFetching, data]);

  useEffect(() => {
    setUserCount(userData?.data)
  }, [userData, userDataIsFetching, userDataIsLoading])

  const { data: statusApplicationCount, isLoading: statusApplicationCountIsLoading, isFetching: statusApplicationCountIsFetching } = useGetApplicationStatusCountQuery('')


  useEffect(() => {
    if (!statusApplicationCountIsFetching && !statusApplicationCountIsLoading) {
      setApplicationStatusCountData(statusApplicationCount?.data || null);
    }
  }, [statusApplicationCountIsLoading, statusApplicationCountIsFetching, statusApplicationCount]); 


  //TO Get IN-Progress  Count of All Apllication
  const {data:InProgressCount , isLoading:isLoadingInProgressCount , isFetching:isFetchingInProgressCount  }=useGetApplicationInProgressStatusCountQuery('')
 
  useEffect(()=>{
 if(!isLoadingInProgressCount && !isFetchingInProgressCount){
  setApplicationInProgressCount(InProgressCount?.data)
 }
  },[InProgressCount ,isLoadingInProgressCount ,isFetchingInProgressCount  ])



  return (
    <>
      <SideNavLayout>

        <DashboardListing
        applicationInProgressCount={applicationInProgressCount}
          pendingApplicationCount={pendingApplicationCount}
          userCount={userCount}
          applicationStatusCount={applicationStatusCountData}
          dashboardData={dashboardCountData}
          isLoading={isLoading || isFetching}
          isPendingLoading={pendngCountIsLoading || pendngCountIsFetching || userDataIsLoading || userDataIsFetching || statusApplicationCountIsLoading || statusApplicationCountIsFetching}
        />
      </SideNavLayout>
    </>
  );
};

export default DashboardListingWrapper;
