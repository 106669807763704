import Tooltip from "@mui/material/Tooltip";
import React from "react";
import { BsCircleFill } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import {
  setIsTableLoading,
  setItems,
  setTotalItems,
  setFilterBy
} from "src/redux/slices/TabWorkHistoryITRApplicationSlice";
import { AppDispatch, RootState } from "src/redux/store";

import { formatDateAndTime } from "src/utils/dateAndTime";
import { applicationStatus } from "src/utils/history/applicationStatus";
import { getColumns } from "src/utils/auth/getColumns";

import TabWorkHistoryApplicationITRListing from "./TabWorkHistoryApplicationITRListing";
import { useGetITRApplicatioWorkHistoryQuery } from "src/services/ITRApplicationServices";
import { ITRListResponse } from "src/models/ITR.model";
import { useLocation } from "react-router-dom";

const paramList = [
  "_id",
  "firstName",
  "middleName",
  "lastName",
  "emailId",
  "mobileNumber",
  "srn",
  "uniqueTransactionId",
  "status",
  "paymentCategory",
  "createdAt",
  "updatedAt"
];

const TabWorkHistoryApplicationITRListingWrapper = () => {

  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)

  const status: any | string | null = queryParams.get('status')
  const dispatch = useDispatch<AppDispatch>();

  const panApplicationState: any = useSelector(
    (state: RootState) => state.tabWorkHistoryITRApplication
  );
  const {
    items,
    rowsPerPage,
    searchValue,
    page,
    filterBy,
    isTableLoading,
    dateFilter,
  } = panApplicationState;

  const { data, isFetching, isLoading } = useGetITRApplicatioWorkHistoryQuery({
    limit: rowsPerPage,
    searchValue: searchValue,
    params: paramList,
    page: page,
    filterBy: filterBy,
    dateFilter: dateFilter,
    orderBy: "createdAt",
    orderByValue: -1,
    isPaginationRequired: true,
  });
  // Setting data
  React.useEffect(() => {
    if (!isFetching && !isLoading) {
      dispatch(setIsTableLoading(false));
      dispatch(setItems(data?.data || []));
      dispatch(setTotalItems(data?.totalItem || 0));
    } else {
      dispatch(setIsTableLoading(true));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isFetching, data]);

  React.useEffect(() => {
    let isExist = filterBy?.find((items: any) => items.fieldName === 'status')

    if (isExist) {
      if (status) {
        dispatch(setFilterBy([
          {
            fieldName: "status",
            value: [status],
          }
        ]))
      }
    }
    else {
      dispatch(setFilterBy((items: any) => [...items, { fieldName: "status", value: [] }]))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status])

  // Table Columns
  const columns: columnTypes[] = [

    {
      field: "createdDateToShow",
      headerName: "Date - Time",
      noAuthRequired: true,
      flex: "flex-[1_1_0%]",
      renderCell: (row: ITRListResponse) => (
        <div>
          <div className="text-slate-700 font-medium">
            {formatDateAndTime(row?.createdDateToShow, "DD MMM yyyy")}
          </div>
          <div className="text-[13px] font-medium text-slate-400">
            {formatDateAndTime(row?.createdDateToShow, "hh:mm A")}
          </div>
        </div>
      ),
    },
    {
      field: "srn",
      headerName: "SRN",
      flex: "flex-[1.5_1.5_0%]",
      renderCell: (row: ITRListResponse) => (
        <span className="text-primary-main text-[13px] text-ellipsis overflow-hidden">
          {row.srn}
        </span>
      ),
    },
    {
      field: "name",
      headerName: "Name",
      flex: "flex-[1_1_0%]",
      renderCell: (row: ITRListResponse) => (
        <span> {`${row.firstName} ${row.middleName} ${row.lastName}`} </span>
      ),
    },
    {
      field: "emailId",
      headerName: "Email",
      flex: "flex-[1.5_1.5_0%]",
      renderCell: (row: ITRListResponse) => {
        return <span> {row.emailId} </span>;
      },
    },
    {
      field: "mobileNumber",
      headerName: "Mobile",
      flex: "flex-[1_1_0%]",
    },
    {
      noAuthRequired: true,
      field: "distributorCode",
      headerName: "SJBT Code",
      flex: "flex-[1.5_1.5_0%]",
      renderCell: (row: ITRListResponse) => {
        return <span> {row.distributorCode} </span>;
      },
    },
    {
      field: "appliedByNumber",
      headerName: "Applied By (Mob. No.)",
      flex: "flex-[1.5_1.5_0%]",
      renderCell: (row: ITRListResponse) => {
        return <span> {row.appliedByNumber || "N/A"} </span>;
      },
    },

    // {
    //   field: "acknowledgementNumber",
    //   headerName: "Acknowledgement Number",
    //   flex: "flex-[1.5_1.5_0%]",
    //   renderCell: (row: ITRListResponse) => {
    //     return (
    //       <Tooltip title={row.acknowledgementNumber}>
    //         <span className="text-primary-main text-[13px] text-ellipsis overflow-hidden">
    //           {row.acknowledgementNumber || "-"}
    //         </span>
    //       </Tooltip>
    //     );
    //   },
    // },

    {
      field: "applicationIndividualPrice",
      headerName: "Amount",
      flex: "flex-[1_1_0%]",
      renderCell: (row: ITRListResponse) => {
        return (
          <Tooltip title={row?.applicationIndividualPrice}>
            <span className="text-green-500 text-[13px] text-ellipsis overflow-hidden font-semibold">
              &#8377; {row?.applicationIndividualPrice || "-"}
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: "verifiedByName",
      headerName: "Verified By",
      flex: "flex-[1_1_0%]",
      renderCell: (row: ITRListResponse) => {
        return (
          <Tooltip title={row?.verifiedByName}>
            <span className="text-primary-main text-[13px] text-ellipsis overflow-hidden font-semibold">
              {row?.verifiedByName || "-"}
            </span>
          </Tooltip>
        );
      },
    },
    {
      field: "rejectedByName",
      headerName: "Rejected By",
      flex: "flex-[1_1_0%]",
      renderCell: (row: ITRListResponse) => {
        return (
          <Tooltip title={row?.rejectedByName}>
            <span className="text-primary-main text-[13px] text-ellipsis overflow-hidden font-semibold">
              {row?.rejectedByName || "-"}
            </span>
          </Tooltip>
        );
      },
    },

    {
      field: "status",
      headerName: "Status",
      flex: "flex-[1.5_1.5_0%]",
      renderCell: (row: ITRListResponse) => {
        return (
          <div className="flex flex-col gap-2 py-2">
            <span
              className={`font-medium rounded-full flex gap-2 items-center bg-slate-100 px-2 py-1 text-[13px]  ${applicationStatus[row?.status]?.className
                }`}
            >
              <BsCircleFill className="text-[10px]" />
              {applicationStatus[row?.status]?.label || "N/A"}{" "}
            </span>
            {/* {(row?.status !== "REJECT" && row?.status !== "PENDING" && row?.status !== "CANCELLED" && row?.status !== "IN_PROGRESS") && (
              <ATMLoadingButton
                onClick={(e) => {
                  e.stopPropagation();
                  // setITRIdVerfiy(row._id || "");
                  setIsOpenReuploadDialog(true);
                }}
                className="py-0 rounded-full text-sky-600  bg-white hover:bg-sky-600 hover:text-white transition-all"
              >
                <div className="text-[12px] py-1 ">
                  Reupload Acknowledgement
                </div>
              </ATMLoadingButton>
            )} */}
          </div>
        );
      },
    },
    {
      field: "rejectionReason",
      headerName: "Rejection Reason",
      flex: "flex-[1_1_0%]",
      renderCell: (row: any) => {
        return (
          <Tooltip title={row?.rejectionReason}>
            <span className="text-primary-main text-[13px] text-ellipsis overflow-hidden font-semibold">
              {row?.rejectionReason || "-"}
            </span>
          </Tooltip>
        );
      },
    },
  ];

  return (
    <>
      <TabWorkHistoryApplicationITRListing
        columns={getColumns(columns, "ITR_APPLICATIONS")}
        rows={items}
        isTableLoading={isTableLoading}
      />
      {/* {isOpenReuploadDialog && (
        <ReuploadAcknowledgementDialogWrapper
          serviceName="PAN"
          onClose={() => setIsOpenReuploadDialog(false)}
          onSubmit={(formData, onComplete) => {
            reuploadAcknowledgment({
              id: PanIdVerfiy || "",
              body: formData,
            }).then((res: any) => {
              onComplete();
              if (res?.error) {
                showToast("error", res?.error?.data?.message);
              } else {
                if (res?.data?.status) {
                  showToast("success", res?.data?.message);
                  setIsOpenReuploadDialog(false);
                } else {
                  showToast("error", res?.data?.message);
                }
              }
            });
          }}
        />
      )} */}

    </>
  );
};

export default TabWorkHistoryApplicationITRListingWrapper;