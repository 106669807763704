import React from "react";
import Dialog from "@mui/material/Dialog";
import { Form, Formik, FormikHelpers } from "formik";
import { object, string } from "yup";
import { showToast } from "src/utils/toaster/showToast";
import { useDeleteRefundEntryInLedgerMutation } from "src/services/RefundBalanceService";
import DeleteRetailerRefundForm from "./DeleteRetailerRefundForm";


export type DeleteRetailerRefundFormValues = {
  otp: string;
};

type Props = {
  onClose: () => void;
  entryId: string;
  deleteAmount :string ;
};

const DeleteRetailerRefundFormWrapper = ({ onClose, entryId ,deleteAmount }: Props) => {
  const [deleteRefundEntry] = useDeleteRefundEntryInLedgerMutation();

  // Form Initial Values
  const initialValues: DeleteRetailerRefundFormValues = {
    otp: "",
  };

  // Validation Schema
  const validationSchema = object().shape({
    otp: string().required("Please enter otp"),
  });

  // Handle Submit
  const handleSubmit = (
    values: DeleteRetailerRefundFormValues,
    { setSubmitting, resetForm }: FormikHelpers<DeleteRetailerRefundFormValues>
  ) => {
    deleteRefundEntry({ id: entryId, body: values }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
          onClose();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Dialog open maxWidth="xs" fullWidth>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <Form>
            <DeleteRetailerRefundForm
              formikProps={formikProps}
              onClose={onClose}
              deleteAmount={deleteAmount}
            />
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default DeleteRetailerRefundFormWrapper;
