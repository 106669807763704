import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { columnTypes } from "src/components/UI/atoms/ATMTable/ATMTable";
import { DSCPaymentDetailResponse } from "src/models/DSCPaymentDetailResponse.model";
import {
  setIsTableLoading,
  setItems,
  setTotalItems,
} from "src/redux/slices/TabPaymentDetailDSCSlice";
import { AppDispatch, RootState } from "src/redux/store";
import { useGetDSCPaymentDetailQuery, useGetDSCPaymentDetailExportMutation } from "src/services/TabFailedPaymentApplicationService";
import { getColumns } from "src/utils/auth/getColumns";
import { formatDateAndTime } from "src/utils/dateAndTime";
import TabPaymentDSCApplicationListing from "./TabPaymentDetailDSCApplicationListing";

// Params List
const paramList = [
  "srn",
  "uniqueTransactionId",
  "mobileNumber" ,
   "propritorName"
];

const exportDataHeaders = [
  { label: "Date-Time", key: "appliedOnDate" },
  { label: "SRN", key: "srn" },
  { label: "Propritor Name", key: "propritorName" },
  { label: "Order Id", key: "uniqueTransactionId" },
  { label: "Mobile", key: "mobileNumber" },
];

const TabPaymentDSCApplicationListingWrapper = () => {
  const dispatch = useDispatch<AppDispatch>();
  const dscState: any = useSelector(
    (state: RootState) => state.TabPaymentDetailDSCSlice
  );
  const {
    items,
    rowsPerPage,
    searchValue,
    page,
    isTableLoading,
    filterBy,
    dateFilter,
  } = dscState;
  const [dscPaymentDetailsData, setDscPaymentDetailsData] = useState<any>({})

  const [exportData] = useGetDSCPaymentDetailExportMutation();
  const [isAllExporting, setIsAllExporting] = useState(false);
  const [isCurrentExporting, setIsCurrentExporting] = useState(false);
  const [dataToExport, setDataToExport] = useState([]);
  // Fetching Applications
  const { data, isFetching, isLoading } = useGetDSCPaymentDetailQuery({
    limit: rowsPerPage,
    searchValue: searchValue,
    params: paramList,
    page: page,
    filterBy: filterBy,
    dateFilter: dateFilter,
    orderBy: "appliedOnDate",
    orderByValue: -1,
    isPaginationRequired: true,
  });

  // Setting data
  useEffect(() => {
    if (!isFetching && !isLoading) {
      dispatch(setIsTableLoading(false));
      dispatch(setItems(data?.data || []));
      setDscPaymentDetailsData(data || {})
      dispatch(setTotalItems(data?.totalItem || 0));
    } else {
      dispatch(setIsTableLoading(true));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isFetching, data]);

  const columns: columnTypes[] = [
    {
      field: "appliedOnDate",
      headerName: "Date - Time",
      noAuthRequired: true,
      flex: "flex-[1_1_0%]",
      renderCell: (row: DSCPaymentDetailResponse) => (
        <div>
          <div className="text-slate-700 font-medium">
            {formatDateAndTime(row.appliedOnDate, "DD MMM yyyy")}
          </div>
          <div className="text-[13px] font-medium text-slate-400">
            {formatDateAndTime(row.appliedOnDate, "hh:mm A")}
          </div>
        </div>
      ),
    },
    {
      field: "srn",
      headerName: "SRN",
      flex: "flex-[1.3_1.3_0%]",
      renderCell: (row: DSCPaymentDetailResponse) => {
        return (
          <span className="text-primary-main text-[13px] text-ellipsis overflow-hidden">
            {row.srn}
          </span>
        );
      },
    },
    {
      field: "propritorName",
      headerName: "Propritor Name",
      flex: "flex-[1_1_0%]",
      renderCell: (row: DSCPaymentDetailResponse) => (
        <span className="text-ellipsis overflow-hidden">
          {row.propritorName}
        </span>
      ),
    },
    {
      field: "uniqueTransactionId",
      headerName: "Order Id",
      flex: "flex-[1.4_1.4_0%]",
      renderCell: (row: DSCPaymentDetailResponse) => {
        return (
          <span className="text-ellipsis overflow-hidden"> {row.uniqueTransactionId} </span>
        );
      },
    },
    {
      field: "mobileNumber",
      headerName: "Mobile",
      flex: "flex-[1_1_0%]",
      renderCell: (row: DSCPaymentDetailResponse) => {
        return <span> {row.mobileNumber} </span>;
      },
    },
  ];

  // Handle Export
  const handleExport = (done: () => void, isAllExport: boolean) => {
    isAllExport ? setIsAllExporting(true) : setIsCurrentExporting(true);
    exportData({
      limit: rowsPerPage,
      searchValue: searchValue,
      params: paramList,
      page: page,
      filterBy: filterBy,
      dateFilter: dateFilter,
      orderBy: "appliedOnDate",
      orderByValue: -1,
      isPaginationRequired: !isAllExport,
    }).then((res: any) => {
      let formattedData = res?.data?.data?.map((data: any) => {
        return {
          ...data,
          createdAt: moment(data?.appliedOnDate).format("DD-MM-yyyy hh:mm A"),
        };
      });
      setDataToExport(formattedData);
      setTimeout(() => {
        done();
        isAllExport ? setIsAllExporting(false) : setIsCurrentExporting(false);
        document.body.click();
      }, 800);
    });
  };
  return (
    <>
      <TabPaymentDSCApplicationListing
        columns={getColumns(columns, "DSC_APPLICATIONS")}
        rows={items}
        onExport={handleExport}
        isAllExporting={isAllExporting}
        isCurrentExporting={isCurrentExporting}
        isTableLoading={isTableLoading}
        exportDataHeaders={exportDataHeaders}
        exportData={dataToExport}
        dscPaymentDetailsData={dscPaymentDetailsData}
      />
    </>
  );
};

export default TabPaymentDSCApplicationListingWrapper;
