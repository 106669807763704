import React, {  useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import ATMPagination from "src/components/UI/atoms/ATMPagination/ATMPagination";
import ATMTable from "src/components/UI/atoms/ATMTable/ATMTable";
import ATMTableHeader from "src/components/UI/atoms/ATMTableHeader/ATMTableHeader";
import {
  setSearchValue,
  setRowsPerPage,
  setPage,
} from "src/redux/slices/TabRetailerRewardSlice";
import { AppDispatch, RootState } from "src/redux/store";
import {
  useGetUserRewardWalletQuery,
} from "src/services/RewardService";
import UpdateRetailerRewardWalletFormWrapper from "../component/UpdateRetailerRewardWallet/UpdateRetailerRewardWalletFormWrapper";

type Props = {
  columns: any[];
  rows: any[];
  onAddNew: () => void;
};

const RetailerTabRewardList = ({ columns, rows, onAddNew }: Props) => { 

  const userType = JSON.parse(localStorage.getItem("userData") || "{}");

  const dispatch = useDispatch<AppDispatch>();
  const rewardState: any = useSelector(
    (state: RootState) => state.tabRetailerReward
  );
  const { page, rowsPerPage, searchValue, totalItems, isTableLoading } =
    rewardState;
  const { retailerId } = useParams();

  //Wallet Amount UseState
  const [isOpenUpdateWalletAmountDialog, setIsOpenUpdateWalletAmountDialog] =
  useState(false);
  // Update Wallet Amount
  // Get Total Wallet Amount Query
  const {
    data: walletAmountData,
  } = useGetUserRewardWalletQuery(retailerId);

 

  return (
    <div className="flex flex-col gap-2 px-4 py-3 h-full">
      {/* Update / Add Wallet Amount  UI */}
    {userType?.type ==='SUPER_ADMIN' &&   <div className="flex justify-between mr-4  items-end">
        {/* Update */}
        <div className="px-3 flex  items-center gap-2 font-semibold text-lg">
            <span className=""> Total Reward Balance</span>: &#x20B9;
            {walletAmountData?.data?.[0]?.totalReward}
            <ATMLoadingButton
              className="h-[40px]"
              onClick={() => {
                setIsOpenUpdateWalletAmountDialog(true); }}
            >
              Update Amount
            </ATMLoadingButton>
          </div>
        {/* Add */}
        <ATMLoadingButton onClick={onAddNew} className="w-[90px]">
          Add
        </ATMLoadingButton>
      </div>}
      <div className="border flex flex-col overflow-auto rounded bg-white">
        {/*Table Header */}
        <ATMTableHeader
          page={page}
          rowCount={totalItems}
          rowsPerPage={rowsPerPage}
          rows={rows}
          onRowsPerPageChange={(newValue) => dispatch(setRowsPerPage(newValue))}
          searchValue={searchValue}
          onSearchChange={(newValue) => dispatch(setSearchValue(newValue))}
        />
        {/* Table */}
        <div className="border flex flex-col grow overflow-auto rounded bg-white">
          <ATMTable columns={columns} rows={rows} isLoading={isTableLoading} />{" "}
        </div>

        {/* Pagination */}
        <div className="flex items-center text-md justify-end border-t border-slate-300">
          <ATMPagination
            page={page}
            rowCount={totalItems}
            rows={rows}
            rowsPerPage={rowsPerPage}
            onPageChange={(newPage) => dispatch(setPage(newPage))}
          />
        </div>
      </div>
      {isOpenUpdateWalletAmountDialog && (
        <UpdateRetailerRewardWalletFormWrapper
          onClose={() => setIsOpenUpdateWalletAmountDialog(false)}
          walletAmountData={walletAmountData?.data?.[0]?.totalReward}
        />
      )}
    </div>
  );
};

export default RetailerTabRewardList;
