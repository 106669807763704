import { IconType } from "react-icons";
import { BsHouseDoor } from "react-icons/bs";
import { FiUser, FiDownloadCloud } from "react-icons/fi";
import { URLSearchParamsInit } from "react-router-dom";
import { switchToAuthModule } from "./utils/auth/switchToAuthModule";
import { AccessAction } from "./utils/Enums/AccessAction";
import { BsFillPersonVcardFill } from "react-icons/bs";
import { LuFileSignature , LuUserPlus } from "react-icons/lu";
import { GiVillage } from "react-icons/gi";
import { CgNotes , CgMenuGridO } from "react-icons/cg";
import { FaMoneyBill1Wave, FaWallet } from "react-icons/fa6";
import { FcDataConfiguration } from "react-icons/fc";
import { IoIdCardOutline } from "react-icons/io5";
import { BiSolidBuildingHouse } from "react-icons/bi";
import { CiVideoOn } from "react-icons/ci";
import { TfiUnlink } from "react-icons/tfi";
import { PiNoteFill , PiCardsDuotone } from "react-icons/pi";
import { RiRefundLine , RiUserSettingsLine , RiMoneyDollarBoxFill , RiRefund2Line } from "react-icons/ri";
import {
  TbBuildingCommunity,
  TbExchangeOff,
  TbBuildingBank,
  TbWorldSearch,
  TbReport,
  TbUserStar,
  TbFloatRight,
  TbReportAnalytics
} from "react-icons/tb";
import { FcSelfServiceKiosk } from "react-icons/fc";
import {
  GrMapLocation,
  GrGallery,
  GrUserExpert,
  GrDocumentConfig,
} from "react-icons/gr";
import { SiCodefactor, SiDialogflow } from "react-icons/si";
import { LiaClipboardListSolid } from "react-icons/lia";
import { FaStar, FaUser, FaPage4, FaQq, FaHistory } from "react-icons/fa";
import {
  MdOutlinePriceChange,
  MdOutlineAppRegistration,
  MdOutlineContactPhone,
  MdOutlineDesignServices,
  MdOutlinePhoneCallback,
  MdOutlineScreenSearchDesktop,
  MdSubscriptions,
  MdOutlinePrivacyTip,
  MdMoneyOff
} from "react-icons/md";import { MdHomeWork } from "react-icons/md";



type NavItemTypeWithoutChild = {
  label: string;
  path: string | undefined;
  icon: IconType;
  badge?: string | number;
  children?: never;
  searchParams?: URLSearchParamsInit;
  moduleName?: string | string[];
  type?: "MODULE" | "ACTION" | "FIELD" | "MODULE_WISE_ACTION";
  accessAction?: string | string[];
  moduleWiseActions?: {
    moduleName: string;
    action: string;
  }[];
};

type NavItemTypeWithChild = {
  label: string;
  path?: never;
  icon: IconType;
  badge?: never;
  searchParams?: URLSearchParamsInit;
  moduleName?: string | string[];
  type?: "MODULE" | "ACTION" | "FIELD" | "MODULE_WISE_ACTION";
  accessAction?: string | string[];
  moduleWiseActions?: {
    moduleName: string;
    action: string;
  }[];

  children: {
    label: string;
    path: string | undefined;
    icon: IconType;
    badge?: string | number;
    searchParams?: URLSearchParamsInit;
    moduleName?: string | string[];
    type?: "MODULE" | "ACTION" | "FIELD" | "MODULE_WISE_ACTION";
    accessAction?: string | string[];
    moduleWiseActions?: {
      moduleName: string;
      action: string;
    }[];
  }[];
};

export type NavItemType = NavItemTypeWithChild | NavItemTypeWithoutChild;

export type PendingApplicationCountType = {
  totaldscapplications: number;
  totalgumastaapplications: number;
  totalitrapplications: number;
  totalmsmeapplications: number;
  totalpanapplications: number;
};

export const navigation: (
  pendingApplicationCount: PendingApplicationCountType
) => NavItemType[] = (pendingApplicationCount: PendingApplicationCountType) => {
  return [
    {
      label: "Dashboard",
      icon: BsHouseDoor,
      path: "/dashboard",
    },
    {
      label: "Distributors",
      moduleName: "DISTRIBUTORS",
      icon: TbUserStar,
      path: "/distributor",
    },
    {
      label: "Retailers",
      moduleName: "RETAILERS",
      icon: GrUserExpert,
      path: "/retailer",
    },
    {
      label: "Guests",
      moduleName: "GUESTS",
      icon: FiUser,
      path: "/guest",
    },
    {
      label: "Ledger",
      moduleName: ["COMMISSIONS", "REWARDS", "REFUND_WALLET_TRANSACTIONS"],
      icon: FaWallet,
      path: switchToAuthModule([
        { moduleName: "COMMISSIONS", path: "/ledger/commission" },
        { moduleName: "REWARDS", path: "/ledger/reward" },
        {
          moduleName: "REFUND_WALLET_TRANSACTIONS",
          path: "/ledger/refund-balance",
        },
      ]),
    },

    {
      label: "Applications",
      moduleName: [
        "PAN_APPLICATIONS",
        "ITR_APPLICATIONS",
        "GUMASTA_APPLICATIONS",
        "DSC_APPLICATIONS",
        "MSME_APPLICATIONS",
        "STC_DIGITAL_PAN",
      ],

      icon: CgNotes,
      children: [
        {
          label: "PAN",
          moduleName: "PAN_APPLICATIONS",
          icon: BsFillPersonVcardFill,
          path: switchToAuthModule([
            {
              moduleName: "PAN_APPLICATIONS",
              action: AccessAction.SHOW_PENDNG_APP,
              path: "/pan/PENDING",
            },
            {
              moduleName: "PAN_APPLICATIONS",
              action: AccessAction.SHOW_IN_PROGRESS_APP,
              path: "/pan/IN_PROGRESS",
            },
            {
              moduleName: "PAN_APPLICATIONS",
              action: AccessAction.SHOW_REJECTED_APP,
              path: "/pan/REJECT",
            },
            {
              moduleName: "PAN_APPLICATIONS",
              action: AccessAction.SHOW_VERIFIED_APP,
              path: "/pan/VERIFY",
            },

            {
              moduleName: "PAN_APPLICATIONS",
              action: AccessAction.SHOW_GENERATED_APP,
              path: "/pan/GENERATE",
            },
            {
              moduleName: "PAN_APPLICATIONS",
              action: AccessAction.SHOW_DONE_APP,
              path: "/pan/DONE",
            },
            {
              moduleName: "PAN_APPLICATIONS",
              action: AccessAction.SHOW_CANCELLED_APP,
              path: "/pan/CANCELLED",
            },
          ]),
          badge: pendingApplicationCount?.totalpanapplications,
        },
        {
          label: "ITR",
          moduleName: "ITR_APPLICATIONS",
          icon: RiMoneyDollarBoxFill,
          path: switchToAuthModule([
            {
              moduleName: "ITR_APPLICATIONS",
              action: AccessAction.SHOW_PENDNG_APP,
              path: "/itr/PENDING",
            },
            {
              moduleName: "ITR_APPLICATIONS",
              action: AccessAction.SHOW_IN_PROGRESS_APP,
              path: "/itr/IN_PROGRESS",
            },
            {
              moduleName: "ITR_APPLICATIONS",
              action: AccessAction.SHOW_REJECTED_APP,
              path: "/itr/REJECT",
            },
            {
              moduleName: "ITR_APPLICATIONS",
              action: AccessAction.SHOW_VERIFIED_APP,
              path: "/itr/VERIFY",
            },

            {
              moduleName: "ITR_APPLICATIONS",
              action: AccessAction.SHOW_GENERATED_APP,
              path: "/itr/GENERATE",
            },
            {
              moduleName: "ITR_APPLICATIONS",
              action: AccessAction.SHOW_DONE_APP,
              path: "/itr/DONE",
            },
            {
              moduleName: "ITR_APPLICATIONS",
              action: AccessAction.SHOW_CANCELLED_APP,
              path: "/itr/CANCELLED",
            },
          ]),
          badge: pendingApplicationCount?.totalitrapplications,
        },
        {
          label: "Gumasta",
          moduleName: "GUMASTA_APPLICATIONS",
          icon: TbBuildingBank,
          path: switchToAuthModule([
            {
              moduleName: "GUMASTA_APPLICATIONS",
              action: AccessAction.SHOW_PENDNG_APP,
              path: "/gumasta/PENDING",
            },
            {
              moduleName: "GUMASTA_APPLICATIONS",
              action: AccessAction.SHOW_IN_PROGRESS_APP,
              path: "/gumasta/IN_PROGRESS",
            },
            {
              moduleName: "GUMASTA_APPLICATIONS",
              action: AccessAction.SHOW_REJECTED_APP,
              path: "/gumasta/REJECT",
            },
            {
              moduleName: "GUMASTA_APPLICATIONS",
              action: AccessAction.SHOW_VERIFIED_APP,
              path: "/gumasta/VERIFY",
            },

            {
              moduleName: "GUMASTA_APPLICATIONS",
              action: AccessAction.SHOW_GENERATED_APP,
              path: "/gumasta/GENERATE",
            },
            {
              moduleName: "GUMASTA_APPLICATIONS",
              action: AccessAction.SHOW_DONE_APP,
              path: "/gumasta/DONE",
            },
            {
              moduleName: "GUMASTA_APPLICATIONS",
              action: AccessAction.SHOW_CANCELLED_APP,
              path: "/gumasta/CANCELLED",
            },
          ]),
          badge: pendingApplicationCount?.totalgumastaapplications,
        },
        {
          label: "DSC(Digital Signature)",
          moduleName: "DSC_APPLICATIONS",
          icon: LuFileSignature,
          path: switchToAuthModule([
            {
              moduleName: "DSC_APPLICATIONS",
              action: AccessAction.SHOW_PENDNG_APP,
              path: "/dsc/PENDING",
            },
            {
              moduleName: "DSC_APPLICATIONS",
              action: AccessAction.SHOW_IN_PROGRESS_APP,
              path: "/dsc/IN_PROGRESS",
            },
            {
              moduleName: "DSC_APPLICATIONS",
              action: AccessAction.SHOW_REJECTED_APP,
              path: "/dsc/REJECT",
            },
            {
              moduleName: "DSC_APPLICATIONS",
              action: AccessAction.SHOW_VERIFIED_APP,
              path: "/dsc/VERIFY",
            },

            {
              moduleName: "DSC_APPLICATIONS",
              action: AccessAction.SHOW_GENERATED_APP,
              path: "/dsc/GENERATE",
            },
            {
              moduleName: "DSC_APPLICATIONS",
              action: AccessAction.SHOW_DONE_APP,
              path: "/dsc/DONE",
            },
            {
              moduleName: "DSC_APPLICATIONS",
              action: AccessAction.SHOW_CANCELLED_APP,
              path: "/dsc/CANCELLED",
            },
          ]),
          badge: pendingApplicationCount?.totaldscapplications,
        },
        {
          label: "MSME Udhyog Adhar",
          moduleName: "MSME_APPLICATIONS",
          icon: GiVillage,
          path: switchToAuthModule([
            {
              moduleName: "MSME_APPLICATIONS",
              action: AccessAction.SHOW_PENDNG_APP,
              path: "/msme/PENDING",
            },
            {
              moduleName: "MSME_APPLICATIONS",
              action: AccessAction.SHOW_IN_PROGRESS_APP,
              path: "/msme/IN_PROGRESS",
            },
            {
              moduleName: "MSME_APPLICATIONS",
              action: AccessAction.SHOW_REJECTED_APP,
              path: "/msme/REJECT",
            },
            {
              moduleName: "MSME_APPLICATIONS",
              action: AccessAction.SHOW_VERIFIED_APP,
              path: "/msme/VERIFY",
            },

            {
              moduleName: "MSME_APPLICATIONS",
              action: AccessAction.SHOW_GENERATED_APP,
              path: "/msme/GENERATE",
            },
            {
              moduleName: "MSME_APPLICATIONS",
              action: AccessAction.SHOW_DONE_APP,
              path: "/msme/DONE",
            },
            {
              moduleName: "MSME_APPLICATIONS",
              action: AccessAction.SHOW_CANCELLED_APP,
              path: "/msme/CANCELLED",
            },
          ]),
          badge: pendingApplicationCount?.totalmsmeapplications,
        },
        {
          label: "Digital PAN",
          moduleName: "DIGITAL_PAN_APPLICATIONS",
          icon: IoIdCardOutline,
          path: "/digital-pan",
        },
      ],
    },
    {
      label: "Enquiries",
      moduleName: [
        "LOAN_ENQUIRIES",
        "BUSINESS_ENQUIRIES",
        "CONTACT_US_ENQUIRIES",
      ],
      icon: TbWorldSearch,
      children: [
        {
          label: "Loan Enquiry",
          moduleName: "LOAN_ENQUIRIES",
          icon: TbFloatRight,
          path: "/loan-enquiry",
        },
        {
          label: "Business Enquiry",
          moduleName: "BUSINESS_ENQUIRIES",
          icon: BiSolidBuildingHouse,
          path: "/business-enquiry",
        },
        {
          label: "Contact Us Enquiry",
          moduleName: "CONTACT_INFOS",
          icon: MdOutlinePhoneCallback,
          path: "/contact-us-enquiry",
        },
      ],
    },
    {
      label: "CMS",
      moduleName: [
        "GALLERIES",
        "BANNERS",
        "POPUP_BANNERS",
        "VIDEOS",
        "VIDEO_TUTORIALS",
        "MENU_LINKS",
        "CONTACT_INFOS",
        "FOOTER_CATEGORIES",
        "FAQS",
        "TERMS_AND_CONDITIONS",
        "PRIVACY_POLICIES",
        "REFUND_AND_CANCELLATIONS",
        "DOWNLOAD_FILES",
        "BUSINESS_OPPORTUNITIES",
        "OTHER_SERVICES",
        "STATIC_PAGES",
      ],
      icon: GrDocumentConfig,
      children: [
        {
          label: "Gallery",
          moduleName: "GALLERIES",
          icon: GrGallery,
          path: "/gallery",
        },
        {
          label: "Banner",
          moduleName: "BANNERS",
          icon: PiCardsDuotone,
          path: "/banner",
        },
        {
          label: "Pop-up Banner",
          moduleName: "POPUP_BANNERS",
          icon: SiDialogflow,
          path: "/pop-up-banner",
        },
        {
          label: "Videos",
          moduleName: ["VIDEOS", "VIDEO_TUTORIALS"],
          icon: CiVideoOn,
          path: "/videos/app-videos",
        },
        {
          label: "Menu Links",
          moduleName: "MENU_LINKS",
          icon: CgMenuGridO,
          path: "/menu-links",
        },
        {
          label: "Contact Information",
          moduleName: "CONTACT_INFOS",
          icon: MdOutlineContactPhone,
          path: "/contact-information",
        },
        {
          label: "Footer Links",
          moduleName: "FOOTER_CATEGORIES",
          icon: TfiUnlink,
          path: "/footer-links",
        },
        {
          label: "FAQ",
          moduleName: "FAQS",
          icon: FaQq,
          path: "/faq",
        },
        {
          label: "Terms & Conditions",
          moduleName: "TERMS_AND_CONDITIONS",
          icon: PiNoteFill,
          path: "/terms-condition",
        },
        {
          label: "Privacy Policy",
          moduleName: "PRIVACY_POLICIES",
          icon: MdOutlinePrivacyTip,
          path: "/privacy-policy",
        },
        {
          label: "Refund Policy",
          moduleName: "REFUND_AND_CANCELLATIONS",
          icon: RiRefundLine,
          path: "/refund-policy",
        },
        {
          label: "Download Forms",
          moduleName: "DOWNLOAD_FILES",
          icon: FiDownloadCloud,
          path: "/download-form",
        },
        {
          label: "Business Oppurtunity",
          moduleName: "BUSINESS_OPPORTUNITIES",
          icon: TbBuildingCommunity,
          path: "/business-opportunity",
        },
        {
          label: "Other Services",
          moduleName: "OTHER_SERVICES",
          icon: MdOutlineDesignServices,
          path: "/other-services",
        },
        {
          label: "Static Pages",
          moduleName: "STATIC_PAGES",
          icon: FaPage4,
          path: "/static-page",
        },
      ],
    },
    {
      label: "Manage Admins",
      moduleName: ["ADMINS", "ADMIN_ROLES"],
      icon: RiUserSettingsLine,
      children: [
        {
          label: "Admin",
          moduleName: "ADMINS",
          icon: LuUserPlus,
          path: "/admins",
        },
        {
          label: "Roles",
          moduleName: "ADMIN_ROLES",
          icon: FaUser,
          path: "/roles",
        },
      ],
    },
    {
      label: "Configuration",
      moduleName: [
        "PAN_CATEGORIES",
        "REWARDS",
        "ITR_CATEGORIES",
        "COMMISSIONS",
        "SUBSCRIPTIONS",
        "CITY_CODES",
        "REJECTION_LISTS",
        "REWARD_POINTS",
        "PRICE_CONFIGS",
        "RETAILER_REGISTER_REWARDS",
      ],
      icon: FcDataConfiguration,
      children: [
        {
          label: "Services",
          moduleName: [
            "PAN_CATEGORIES",
            "ITR_CATEGORIES",
            "COMMISSIONS",
            "REWARDS",
          ],
          icon: FcSelfServiceKiosk,
          path: "/services/PAN/categories",
        },
        {
          label: "Subscription",
          moduleName: "SUBSCRIPTIONS",
          icon: MdSubscriptions,
          path: "/subscription",
        },
        {
          label: "Gumasta Location",
          moduleName: "GUMASTA_CONFIGURATION",
          icon: GrMapLocation,
          path: "/gumastaConfiguration",
        },
        {
          label: "AO Code List",
          moduleName: "CITY_CODES",
          icon: SiCodefactor,
          path: "/ao-code-list",
        },
        {
          label: "Rejection List",
          moduleName: "REJECTION_LISTS",
          icon: LiaClipboardListSolid,
          path: "/rejection-list",
        },
        {
          label: "Reward Point Value",
          moduleName: "REWARD_POINTS",
          icon: FaStar,
          path: "/reward-point-value",
        },
        {
          label: "Base Price",
          moduleName: "PRICE_CONFIGS",
          icon: MdOutlinePriceChange,
          path: "/base-price",
        },
        {
          label: "New Registration Reward",
          moduleName: "RETAILER_REGISTER_REWARDS",
          icon: MdOutlineAppRegistration,
          path: "/new-registration-reward",
        },
      ],
    },
    {
      label: "Refund Request",
      moduleName: "REFUND_REQUESTS",
      icon: RiRefund2Line,
      path: "/refund-request",
    },
    {
      label: "History",
      moduleName: [
        "PAN_APPLICATIONS",
        "ITR_APPLICATIONS",
        "MSME_APPLICATIONS",
        "GUMASTA_APPLICATIONS",
        "DSC_APPLICATIONS",
      ],
      icon: FaHistory,
      path: switchToAuthModule([
        {
          moduleName: "PAN_APPLICATIONS",
          action: AccessAction.HISTORY,
          path: "/history/pan",
        },
        {
          moduleName: "ITR_APPLICATIONS",
          action: AccessAction.HISTORY,
          path: "/history/itr",
        },
        {
          moduleName: "MSME_APPLICATIONS",
          action: AccessAction.HISTORY,
          path: "/history/msme",
        },
        {
          moduleName: "GUMASTA_APPLICATIONS",
          action: AccessAction.HISTORY,
          path: "/history/gumasta",
        },
        {
          moduleName: "DSC_APPLICATIONS",
          action: AccessAction.HISTORY,
          path: "/history/dsc",
        },
      ]),
    },
    {
      label: "Work History",
      moduleName: [
        "PAN_APPLICATIONS",
        "ITR_APPLICATIONS",
        "MSME_APPLICATIONS",
        "GUMASTA_APPLICATIONS",
        "DSC_APPLICATIONS",
      ],
      icon: MdHomeWork,
      path: switchToAuthModule([
        {
          moduleName: "PAN_APPLICATIONS",
          action: AccessAction.WORK_HISTORY,
          path: "/work-history/pan",
        },
        {
          moduleName: "ITR_APPLICATIONS",
          action: AccessAction.WORK_HISTORY,
          path: "/work-history/itr",
        },
        {
          moduleName: "MSME_APPLICATIONS",
          action: AccessAction.WORK_HISTORY,
          path: "/work-history/msme",
        },
        {
          moduleName: "GUMASTA_APPLICATIONS",
          action: AccessAction.WORK_HISTORY,
          path: "/work-history/gumasta",
        },
        {
          moduleName: "DSC_APPLICATIONS",
          action: AccessAction.WORK_HISTORY,
          path: "/work-history/dsc",
        },
      ]),
    },
    {
      label: "Payment Failed",
      icon: MdMoneyOff,
      type: "MODULE_WISE_ACTION",
      moduleWiseActions: [
        {
          moduleName: "PAN_APPLICATIONS",
          action: AccessAction.SHOW_FAILED_PAYMENTS,
        },
        {
          moduleName: "ITR_APPLICATIONS",
          action: AccessAction.SHOW_FAILED_PAYMENTS,
        },
        {
          moduleName: "MSME_APPLICATIONS",
          action: AccessAction.SHOW_FAILED_PAYMENTS,
        },
        {
          moduleName: "GUMASTA_APPLICATIONS",
          action: AccessAction.SHOW_FAILED_PAYMENTS,
        },
        {
          moduleName: "DSC_APPLICATIONS",
          action: AccessAction.SHOW_FAILED_PAYMENTS,
        },
      ],
      path: switchToAuthModule([
        {
          moduleName: "PAN_APPLICATIONS",
          action: AccessAction.SHOW_FAILED_PAYMENTS,
          path: "/payment_failed/pan",
        },
        {
          moduleName: "ITR_APPLICATIONS",
          action: AccessAction.SHOW_FAILED_PAYMENTS,
          path: "/payment_failed/itr",
        },
        {
          moduleName: "MSME_APPLICATIONS",
          action: AccessAction.SHOW_FAILED_PAYMENTS,
          path: "/payment_failed/msme",
        },
        {
          moduleName: "GUMASTA_APPLICATIONS",
          action: AccessAction.SHOW_FAILED_PAYMENTS,
          path: "/payment_failed/gumasta",
        },
        {
          moduleName: "DSC_APPLICATIONS",
          action: AccessAction.SHOW_FAILED_PAYMENTS,
          path: "/payment_failed/dsc",
        },
      ]),
    },
    {
      label: "Reports",
      icon: TbReport,
      moduleName: "ADMINS",
      type: "ACTION",
      accessAction: [
        AccessAction.ADMIN_REPORTS,
        AccessAction.DISTRIBUTOR_REPORTS,
        AccessAction.RETAILER_REPORTS,
      ],

      children: [
        {
          label: "Admin Report",
          type: "ACTION",
          moduleName: "ADMINS",
          accessAction: AccessAction.ADMIN_REPORTS,
          icon: TbReportAnalytics,
          path: "/admin-report",
        },
        {
          label: "Distributor Report",
          type: "ACTION",
          moduleName: "ADMINS",
          accessAction: AccessAction.DISTRIBUTOR_REPORTS,
          icon: TbUserStar,
          path: "/distributor-report",
        },
        {
          label: "Retailer Report",
          type: "ACTION",
          moduleName: "ADMINS",
          accessAction: AccessAction.RETAILER_REPORTS,
          icon: GrUserExpert,
          path: "/retailer-report",
        },
      ],
    },
    {
      label: "Payment Detail",
      icon: FaMoneyBill1Wave,
      type: "MODULE_WISE_ACTION",
      moduleWiseActions: [
        {
          moduleName: "PAN_APPLICATIONS",
          action: AccessAction.SHOW_PAYMENTS_DETAILS,
        },
        {
          moduleName: "ITR_APPLICATIONS",
          action: AccessAction.SHOW_PAYMENTS_DETAILS,
        },
        {
          moduleName: "MSME_APPLICATIONS",
          action: AccessAction.SHOW_PAYMENTS_DETAILS,
        },
        {
          moduleName: "GUMASTA_APPLICATIONS",
          action: AccessAction.SHOW_PAYMENTS_DETAILS,
        },
        {
          moduleName: "DSC_APPLICATIONS",
          action: AccessAction.SHOW_PAYMENTS_DETAILS,
        },
      ],
      path: switchToAuthModule([
        {
          moduleName: "PAN_APPLICATIONS",
          action: AccessAction.SHOW_PAYMENTS_DETAILS,
          path: "/payment-detail/pan",
        },
        {
          moduleName: "ITR_APPLICATIONS",
          action: AccessAction.SHOW_PAYMENTS_DETAILS,
          path: "/payment-detail/itr",
        },
        {
          moduleName: "MSME_APPLICATIONS",
          action: AccessAction.SHOW_PAYMENTS_DETAILS,
          path: "/payment-detail/msme",
        },
        {
          moduleName: "GUMASTA_APPLICATIONS",
          action: AccessAction.SHOW_PAYMENTS_DETAILS,
          path: "/payment-detail/gumasta",
        },
        {
          moduleName: "DSC_APPLICATIONS",
          action: AccessAction.SHOW_PAYMENTS_DETAILS,
          path: "/payment-detail/dsc",
        },
      ]),
    },
    {
      label: "Search-History",
      moduleName: [
        "PAN_APPLICATIONS",
        "ITR_APPLICATIONS",
        "MSME_APPLICATIONS",
        "GUMASTA_APPLICATIONS",
        "DSC_APPLICATIONS",
      ],
      icon: MdOutlineScreenSearchDesktop,
      path: switchToAuthModule([
        {
          moduleName: "PAN_APPLICATIONS",
          action: AccessAction.SEARCH_HISTORY,
          path: "/search-history/pan",
        },
        {
          moduleName: "ITR_APPLICATIONS",
          action: AccessAction.SEARCH_HISTORY,
          path: "/search-history/itr",
        },
        {
          moduleName: "MSME_APPLICATIONS",
          action: AccessAction.SEARCH_HISTORY,
          path: "/search-history/msme",
        },
        {
          moduleName: "GUMASTA_APPLICATIONS",
          action: AccessAction.SEARCH_HISTORY,
          path: "/search-history/gumasta",
        },
        {
          moduleName: "DSC_APPLICATIONS",
          action: AccessAction.SEARCH_HISTORY,
          path: "/search-history/dsc",
        },
      ]),
    },
    {
      label: "Change Password",
      moduleName: "ADMINS",
      type: "ACTION",
      accessAction: AccessAction.ADMIN_CHANGE_PASSWORD,
      icon: TbExchangeOff,
      path: "/change-password",
    },
  ];
};
