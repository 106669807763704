import apiSlice from "./ApiSlice";

export const dashboardApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    // GET REQUEST
    getDashboardData: builder.query({
      providesTags: ["dashboard"],
      query: () => ({
        url: "/dashboard/application-count",
        method: "GET",
      }),
    }),
    getTotalUserCount: builder.query({
      providesTags: ["dashboard"],
      query: () => ({
        url: "/dashboard/user-count",
        method: "GET",
      }),
    }),
    getApplicationStatusCount: builder.query({
      providesTags: [
        "dashboard",
        "gumasta-application",
        "msme-application",
        "pan-app",
        "dsc-application",
        "itr-application",
      ],
      query: () => ({
        url: "/dashboard/status-count",
        method: "GET",
      }),
    }),
    getApplicationInProgressStatusCount: builder.query({
      providesTags: [
        "dashboard",
        "gumasta-application",
        "msme-application",
        "pan-app",
        "dsc-application",
        "itr-application",
      ],
      query: () => ({
        url: "/dashboard/inprogress-count",
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetDashboardDataQuery,
  useGetTotalUserCountQuery,
  useGetApplicationStatusCountQuery,
  useGetApplicationInProgressStatusCountQuery
} = dashboardApi;
