import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ATMExportButtonGroup from "src/components/UI/atoms/ATMExportButtonGroup/ATMExportButtonGroup";
import ATMPagination from "src/components/UI/atoms/ATMPagination/ATMPagination";
import ATMTable from "src/components/UI/atoms/ATMTable/ATMTable";
import ATMTableHeader from "src/components/UI/atoms/ATMTableHeader/ATMTableHeader";
import {
  setRowsPerPage,
  setPage,
  setSearchValue,
} from "src/redux/slices/TabPaymentDetailMSMESlice";
import { AppDispatch, RootState } from "src/redux/store";
import AuthHOC from "src/userAccess/AuthHOC";
import { AccessAction } from "src/utils/Enums/AccessAction";
import FilterCardWrapper from "./TabPaymentDetailsMSMEApplicationFilterCard/FilterCardWrapper";

type Props = {
  columns: any[];
  rows: any[];
  onExport: (done: () => void, isAllExport: boolean) => void;
  isAllExporting: boolean;
  isCurrentExporting: boolean;
  isTableLoading: boolean;
  exportDataHeaders: any;
  exportData: any;
  msmePaymentDetailsData: any
};

const TabPaymentDetailMSMEApplicationListing = ({
  columns,
  rows,
  onExport,
  isAllExporting,
  isCurrentExporting,
  isTableLoading,
  exportDataHeaders,
  exportData,
  msmePaymentDetailsData

}: Props) => {
  const dispatch = useDispatch<AppDispatch>();
  const msmeState = useSelector(
    (state: RootState) => state.TabPaymentDetailMSMESlice
  );
  const { page, rowsPerPage, totalItems, searchValue } = msmeState;

  return (

    <div className="flex flex-col gap-2 px-4 h-full mt-4 ">
      <div className="flex justify-between  gap-2 px-2 mt-2 items-center">
        <div className="flex gap-6 font-medium">
          <span>Total Reward :  ₹{msmePaymentDetailsData?.totalReward || 0}
          </span>
          <span>Total Refund :  ₹{msmePaymentDetailsData?.totalRefund || 0}
          </span>
          <span>Total Payment Gateway :  ₹{msmePaymentDetailsData?.totalPaymentGateway || 0}
          </span>
        </div>
        <div className="justify-end">
          <ATMExportButtonGroup
            isAllExporting={isAllExporting}
            isCurrentExporting={isCurrentExporting}
            onExport={onExport}
            allExportFileName="all-History-Pan.csv"
            currentExportFileName="current-History-Pan.csv"
            exportDataHeaders={exportDataHeaders}
            exportData={exportData}
          />
        </div>
      </div>
      <div className="border border-slate-100 flex flex-col overflow-auto rounded bg-white ">
        {/*Table Header */}
        <ATMTableHeader
          page={page}
          rowCount={totalItems}
          renderFilter={(close) => <FilterCardWrapper close={close} />}
          rowsPerPage={rowsPerPage}
          rows={rows}
          onRowsPerPageChange={(newValue) =>
            dispatch(setRowsPerPage(newValue))
          }
          searchValue={searchValue}
          onSearchChange={(newValue) => dispatch(setSearchValue(newValue))}
          isFilter
        />

        {/* Table */}
        <div className="border flex flex-col grow overflow-auto rounded bg-white">
          <ATMTable
            columns={columns}
            disableRowClick={
              !AuthHOC({
                type: "ACTION",
                moduleName: "MSME_APPLICATIONS",
                action: AccessAction.VIEW,
                resultType: "BOOLEAN",
              })
            }
            rows={rows}
            isLoading={isTableLoading}
          />{" "}
        </div>

        {/* Pagination */}
        <div className="flex items-center justify-end border-t border-slate-300">
          <ATMPagination
            page={page}
            rowCount={totalItems}
            rows={rows}
            rowsPerPage={rowsPerPage}
            onPageChange={(newPage) => dispatch(setPage(newPage))}
          />
        </div>
      </div>
    </div>
  );
};

export default TabPaymentDetailMSMEApplicationListing;
