import React, { useEffect, useState } from "react";
import TabPaymentSummaryListing from "./TabPaymentSummaryListing";
import { useGetPanApplicationPaymentSummaryQuery } from "src/services/PANService";
import { useGetItrApplicationPaymentSummaryQuery } from "src/services/ITRApplicationServices";
import { useGetGumastaApplicationPaymentSummaryQuery } from "src/services/GumastaService";
import { useGetMsmeApplicationPaymentSummaryQuery } from "src/services/MSMEService";
import { useGetDscApplicationPaymentSummaryQuery } from "src/services/DSCService";
import { useSelector } from "react-redux";
import { RootState } from "src/redux/store";
import moment from "moment";

const TabPaymentSummaryListingWrapper = () => {
  const [panPaymentData, setPanPayementData] = useState<any>({});
  const [itrPaymentData, setItrPayementData] = useState<any>({});
  const [dscPaymentData, setDscPayementData] = useState<any>({});
  const [msmePaymentData, setMsmePayementData] = useState<any>({});
  const [gumastaPaymentData, setGumastaPayementData] = useState<any>({});

  const panApplicationState: any = useSelector(
    (state: RootState) => state.tabPaymentDetailSummarySlice
  );
  const { dateFilter } = panApplicationState;

  //PAN Application Data

  const {
    data: panData,
    isLoading: panDataIsLoading,
    isFetching: panDataIsFetching,
  } = useGetPanApplicationPaymentSummaryQuery({
    dateFilter: {
      dateFilterKey: "appliedOnDate",
      start_date: dateFilter?.start_date  ? moment(dateFilter?.start_date)?.format("yyyy-MM-DD") : '',
      end_date: dateFilter?.end_date  ? moment(dateFilter?.end_date)?.format("yyyy-MM-DD") :'',
    },
  });

  useEffect(() => {
    if (!panDataIsFetching && !panDataIsLoading) {
      setPanPayementData(panData);
    }
  }, [panData, panDataIsFetching, panDataIsLoading]);

  //Itr Application Data

  const {
    data: itrData,
    isLoading: itrDataIsLoading,
    isFetching: itrDataIsFetching,
  } = useGetItrApplicationPaymentSummaryQuery({
    dateFilter: {
      dateFilterKey: "appliedOnDate",
      start_date: dateFilter?.start_date  ? moment(dateFilter?.start_date)?.format("yyyy-MM-DD") : '',
      end_date: dateFilter?.end_date  ? moment(dateFilter?.end_date)?.format("yyyy-MM-DD") :'',
    },
  });

  useEffect(() => {
    if (!itrDataIsFetching && !itrDataIsLoading) {
      setItrPayementData(itrData);
    }
  }, [itrData, itrDataIsFetching, itrDataIsLoading]);

  //Dsc Application Data

  const {
    data: dscData,
    isLoading: dscDataIsLoading,
    isFetching: dscDataIsFetching,
  } = useGetDscApplicationPaymentSummaryQuery({
    dateFilter: {
      dateFilterKey: "appliedOnDate",
      start_date: dateFilter?.start_date  ? moment(dateFilter?.start_date)?.format("yyyy-MM-DD") : '',
      end_date: dateFilter?.end_date  ? moment(dateFilter?.end_date)?.format("yyyy-MM-DD") :'',
    },
  });

  useEffect(() => {
    if (!dscDataIsFetching && !dscDataIsLoading) {
      setDscPayementData(dscData);
    }
  }, [dscData, dscDataIsFetching, dscDataIsLoading]);

  //Msme Application Data

  const {
    data: msmeData,
    isLoading: msmeDataIsLoading,
    isFetching: msmeDataIsFetching,
  } = useGetMsmeApplicationPaymentSummaryQuery({
    dateFilter: {
      dateFilterKey: "appliedOnDate",
      start_date: dateFilter?.start_date  ? moment(dateFilter?.start_date)?.format("yyyy-MM-DD") : '',
      end_date: dateFilter?.end_date  ? moment(dateFilter?.end_date)?.format("yyyy-MM-DD") :'',
    },
  });

  useEffect(() => {
    if (!msmeDataIsFetching && !msmeDataIsLoading) {
      setMsmePayementData(msmeData);
    }
  }, [msmeData, msmeDataIsFetching, msmeDataIsLoading]);

  //Gumasta Application Data

  const {
    data: gumastaData,
    isLoading: gumastaDataIsLoading,
    isFetching: gumastaDataIsFetching,
  } = useGetGumastaApplicationPaymentSummaryQuery({
    dateFilter: {
      dateFilterKey: "appliedOnDate",
      start_date: dateFilter?.start_date  ? moment(dateFilter?.start_date)?.format("yyyy-MM-DD") : '',
      end_date: dateFilter?.end_date  ? moment(dateFilter?.end_date)?.format("yyyy-MM-DD") :'',
    },
  });

  useEffect(() => {
    if (!gumastaDataIsFetching && !gumastaDataIsLoading) {
      setGumastaPayementData(gumastaData);
    }
  }, [gumastaData, gumastaDataIsFetching, gumastaDataIsLoading]);

  const PaymentGatewayPayment =
    parseInt(panPaymentData?.totalPaymentGateway) +
    parseInt(itrPaymentData?.totalPaymentGateway) +
    parseInt(msmePaymentData?.totalPaymentGateway) +
    parseInt(dscPaymentData?.totalPaymentGateway) +
    parseInt(gumastaPaymentData?.totalPaymentGateway);

  const RewardPayment =
    parseInt(panPaymentData?.totalReward) +
    parseInt(itrPaymentData?.totalReward) +
    parseInt(msmePaymentData?.totalReward) +
    parseInt(dscPaymentData?.totalReward) +
    parseInt(gumastaPaymentData?.totalReward);

  const RefundPayment =
    parseInt(panPaymentData?.totalRefund) +
    parseInt(itrPaymentData?.totalRefund) +
    parseInt(msmePaymentData?.totalRefund) +
    parseInt(dscPaymentData?.totalRefund) +
    parseInt(gumastaPaymentData?.totalRefund);

  return (
    <>
      <TabPaymentSummaryListing
        payemntGateway={PaymentGatewayPayment}
        RewardPayment={RewardPayment}
        RefundPayment={RefundPayment}
        isLoading={
          gumastaDataIsFetching ||
          gumastaDataIsLoading ||
          msmeDataIsFetching ||
          msmeDataIsLoading ||
          dscDataIsFetching ||
          dscDataIsLoading ||
          panDataIsFetching ||
          panDataIsLoading ||
          itrDataIsFetching ||
          itrDataIsLoading
        }
      />
    </>
  );
};

export default TabPaymentSummaryListingWrapper;
