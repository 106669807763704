import { FormikProps } from "formik";
import ATMLoadingButton from "src/components/UI/atoms/ATMLoadingButton/ATMLoadingButton";
import ATMTextField from "src/components/UI/atoms/formFields/ATMTextField/ATMTextField";
import { handleValidNumber } from "src/utils/regularExpression";
import { IoClose } from "react-icons/io5";
import { useGetAddPaymentOtpMutation } from "src/services/DistributorServices";
import { showToast } from "src/utils/toaster/showToast";
import { DistributorRefundDeleteValues } from "./DeleteRefundBalanceFormWrapper";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

type Props = {
  formikProps: FormikProps<DistributorRefundDeleteValues>;
  onClose: () => void;
  deleteAmount:string 
};

const DeleteRefundBalanceForm = ({ formikProps, onClose , deleteAmount }: Props) => {
  const { values, setFieldValue, isSubmitting, handleBlur } = formikProps;
  const [counter, setCounter] = useState(0);
  const [isEnableOtpField, setIsEnableOtpField] = useState(false);
  const {distributorId} = useParams()

  const [getOtp, getOtpInfo] = useGetAddPaymentOtpMutation();

    useEffect(() => {
        const timer =
        counter > 0 ? setInterval(() => setCounter(counter - 1), 1000) : 0;
        return () => clearInterval(timer);
    }, [counter]);

  const handleGetOtp = () => {
    getOtp({
      userId: distributorId,
      type: "DELETE",
      walletType: "REFUND",
      amount : deleteAmount
    }).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          setIsEnableOtpField(true)
          setCounter(59);
        } else {
          showToast("error", res?.data?.message);
        }
      }
    });
  };
  return (
    <div className="flex p-4 flex-col gap-6 relative">
      <div className="flex justify-between items-center">
        <div className="text-xl font-medium">Delete Refund</div>

        <button
          type="button"
          onClick={onClose}
          className="p-2 rounded-full flex justify-center items-center bg-slate-200 hover:bg-red-400 hover:text-white transition-all"
        >
          <IoClose />
        </button>
      </div>
      <div className="flex flex-col gap-4">
        {/* OTP */}
        <div>
          <ATMTextField
            name="otp"
            disabled={!isEnableOtpField}
            required
            value={values?.otp}
            onChange={(e) =>
              handleValidNumber(e) && setFieldValue("otp", e.target.value)
            }
            label="OTP"
            placeholder="Enter Otp"
            onBlur={handleBlur}
          />
        </div>

        <div className="flex justify-center text-center  text-[16px] font-medium">
          {counter <= 0 ? (
            <div>
              {/* <p> Didn &#x0315; t get the OTP</p> */}
              <div className="flex justify-center ">
                <ATMLoadingButton 
                 type='button'
                  isLoading={getOtpInfo?.isLoading}
                  className="h-[36px] bg-blue-700"
                  onClick={() => {
                    handleGetOtp();
                  }}
                >
                  Send OTP
                </ATMLoadingButton>
              </div>
            </div>
          ) : (
            <div>
              <p> Otp Resend in</p>
              <p className="text-link">
                00:
                {counter?.toString()?.length < 2 ? "0" + counter : counter}
              </p>
            </div>
          )}
        </div>
      </div>
      <div>
        <ATMLoadingButton isLoading={isSubmitting} type="submit">
          Delete
        </ATMLoadingButton>
      </div>
    </div>
  );
};

export default DeleteRefundBalanceForm;
