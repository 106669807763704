import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import { Form, Formik, FormikHelpers } from "formik";
import { object, string } from "yup";
import { showToast } from "src/utils/toaster/showToast";
import { useUpdateUserRefundWalletMutation } from "src/services/RefundBalanceService";

import { useParams } from "react-router-dom";
import UpdateRetailerRefundWalletForm from "./UpdateRetailerRefundWalletForm";

export type UpdateRetailerRefundWalletFormValues = {
  otp: string;
  amountToBeUpdate: string;
};

type Props = {
  onClose: () => void;
  walletAmountData: string;
};

const UpdateRetailerRefundWalletFormWrapper = ({
  onClose,
  walletAmountData,
}: Props) => {
  const [updateWallet] = useUpdateUserRefundWalletMutation();
  const [wantToDisabledFiled, setWantToDisabledFiled] = useState(false);

  const { retailerId } = useParams();

  // Form Initial Values
  const initialValues: UpdateRetailerRefundWalletFormValues = {
    otp: "",
    amountToBeUpdate: walletAmountData,
  };

  // Validation Schema
  const validationSchema = object().shape({
    otp: string().required("Please enter otp"),
    amountToBeUpdate: string().required("Please enter amount To BeUpdate"),
  });

  // Handle Submit
  const handleSubmit = (
    values: UpdateRetailerRefundWalletFormValues,
    {
      setSubmitting,
      resetForm,
    }: FormikHelpers<UpdateRetailerRefundWalletFormValues>
  ) => {
    const formattedValues = {
      otp: values?.otp,
      amountToBeUpdate: values?.amountToBeUpdate,
      userId: retailerId,
    };

    updateWallet(formattedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          resetForm();
          onClose();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Dialog open maxWidth="xs" fullWidth>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <Form>
            <UpdateRetailerRefundWalletForm
              setWantToDisabledFiled={setWantToDisabledFiled}
              wantToDisabledFiled={wantToDisabledFiled}
              formikProps={formikProps}
              onClose={onClose}
            />
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default UpdateRetailerRefundWalletFormWrapper;
