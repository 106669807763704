import React from "react";
import { BiSearch } from "react-icons/bi";
import ATMTable from "src/components/UI/atoms/ATMTable/ATMTable";
import { setPanSearchHistoryValue } from "src/redux/slices/SearchHistorySlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { PANListResponse } from "src/models/PAN.model";
import AuthHOC from "src/userAccess/AuthHOC";
import { AccessAction } from "src/utils/Enums/AccessAction";
import NotAuthorizedPage from "src/components/UI/NotAuthorized/NotAuthorizedPage";

type Props = {
  columns: any;
  row: any;
  searchValue: string;
  isLoading: boolean;
};

const TabSearchHistoryApplicationPANListing = ({
  columns,
  row,
  searchValue,
  isLoading,
}: Props) => {
  const dispatch = useDispatch();
  const navigate =  useNavigate()

  return ( 
    <AuthHOC
    moduleName="PAN_APPLICATIONS"
    action={AccessAction.SEARCH_HISTORY}
    alt={<NotAuthorizedPage/>}
  >
    <div className="p-6">
      <div className="border-b py-2">
        <div className="flex gap-1 ">
          <div className="border border-slate-400   rounded flex items-center p-1 hover:border-primary-main bg-slate-100">
            <BiSearch className="text-slate-600 text-xl" />
            <input
              className="border-none rounded outline-none px-2 w-[200px] placeholder:text-slate-500 bg-slate-100"
              value={searchValue}
              onChange={(e) => {
                dispatch(setPanSearchHistoryValue(e.target.value));
              }}
              placeholder="Search..."
            />
          </div>
        </div>
      </div>
      <div className=" border flex flex-col grow overflow-auto rounded bg-white mt-2">
        <ATMTable columns={columns} rows={row} isLoading={isLoading}  onRowClick={(row: PANListResponse) => navigate(`/pan/${row._id}`)} />
      </div>
    </div>
    </AuthHOC>
  );
};

export default TabSearchHistoryApplicationPANListing;
