import React from "react";
import { AiOutlineRight } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import ATMBreadCrumbs, {
  BreadcrumbType,
} from "src/components/UI/atoms/ATMBreadCrumbs/ATMBreadCrumbs";
import {
  DashbaordListResponse,
  DashbaordPendingListResponse,
} from "src/models/Dashboard.model";

// BreadCrumbs
const breadcrumbs: BreadcrumbType[] = [
  {
    label: "Admin",
    path: "/dashboard",
  },
  {
    label: "Dashboard",
  },
];

type DashboardData = DashbaordListResponse | null;
type DashBoardPendingData = DashbaordPendingListResponse | null;
type Props = {
  pendingApplicationCount: DashBoardPendingData;
  dashboardData: DashboardData;
  isLoading: boolean;
  isPendingLoading: boolean;
  userCount: any;
  applicationStatusCount: any;
  applicationInProgressCount: any;
};

const DashboardListing = ({
  dashboardData,
  isLoading,
  pendingApplicationCount,
  isPendingLoading,
  userCount,
  applicationStatusCount,
  applicationInProgressCount,
}: Props) => {
  const navigate = useNavigate();
  type CardPropType = {
    label: React.ReactNode;
    value?: React.ReactNode;
    path?: string;
    className: string;

    labelVerify?: React.ReactNode;
    valueVerify?: React.ReactNode;
    pathVerify?: string;

    labelSmall?: React.ReactNode;
    valueSmall?: React.ReactNode;
    pathSmall?: string;

    labelDone?: string;
    valueDone?: React.ReactNode;
    pathDone?: string;

    labelInprogress?: string;
    valueInprogress?: React.ReactNode;
    pathInprogress?: string;

    labelReject?: string;
    valueReject?: React.ReactNode;
    pathReject?: string;

    labelGenerate?:string;
    valueGenerate?:React.ReactNode;
    pathGenerate?:string;
  };
  const userType = JSON.parse(localStorage.getItem("userData") || "{}");

  const InfoCard = ({
    value,
    label,
    path,
    className,
    labelSmall,
    valueSmall,
    pathSmall,
  }: CardPropType) => {
    if (isLoading || isPendingLoading) {
      return (
        <div
          className={`flex flex-col gap-2 bg-white rounded-lg shadow-lg p-6 border border-black animate-pulse ${className} `}
        >
          <h3 className="w-[220px] h-[25px] bg-slate-300 "> </h3>
          <p className=" w-[120px] h-[30px] bg-slate-300"> </p>
          <div className="w-[100px] h-[20px] bg-slate-300 mt-2"></div>
        </div>
      );
    }

    return (
      <div
        className={`bg-white rounded-lg shadow-lg p-6 border border-black ${className} flex justify-between`}
      >
        <div>
          <h3 className="text-sm text-white font-medium">{label}</h3>
          <p className="text-4xl text-white font-semibold pt-2">{value}</p>
          <button
            onClick={() => navigate(`/${path}`)}
            className="flex text-base text-white  pt-2 "
          >
            <div>MORE INFO </div>
            <div className="pt-1 pl-2 ">
              <AiOutlineRight />
            </div>
          </button>
        </div>

        {labelSmall && (
          <div>
            <h3 className="text-sm text-white font-medium">{labelSmall}</h3>
            <p className="text-4xl text-white font-semibold pt-2">
              {valueSmall}
            </p>
            <button
              onClick={() => navigate(`/${pathSmall}`)}
              className="flex text-base text-white  pt-2 "
            >
              <div>MORE INFO </div>
              <div className="pt-1 pl-2 ">
                <AiOutlineRight />
              </div>
            </button>
          </div>
        )}
      </div>
    );
  };

  const InfoStatusCard = ({
    value,
    label,
    path,
    className,
    labelDone,
    valueDone,
    pathDone,
    labelInprogress,
    labelVerify,
    valueVerify,
    pathVerify,
    valueInprogress,
    pathInprogress,
    labelReject,
    valueReject,
    pathReject,
    labelGenerate ,
    valueGenerate ,
    pathGenerate ,
  }: CardPropType) => {
    if (isLoading || isPendingLoading) {
      return (
        <div
          className={`flex flex-col gap-2 bg-white rounded-lg shadow-lg p-6 border border-black animate-pulse ${className} `}
        >
          <h3 className="w-[220px] h-[25px] bg-slate-300 "> </h3>
          <p className=" w-[120px] h-[30px] bg-slate-300"> </p>
          <div className="w-[100px] h-[20px] bg-slate-300 mt-2"></div>
        </div>
      );
    }

    return (
      <div
        className={`bg-white rounded-lg shadow-lg p-6 border border-black ${className} `}
      >
        <div className="flex justify-between mb-2">
          <div>
            <h3 className="text-base text-white font-medium">{label}</h3>
            {/* <p className="text-4xl text-white font-semibold pt-2">{value}</p> */}
          </div>
          {/* <button
            onClick={() => navigate(`/${path}`)}
            className="flex text-base text-white"
          >
            <div>MORE INFO </div>
            <div className="pt-1 pl-2 ">
              <AiOutlineRight />
            </div>
          </button> */}
        </div>

        <div className="flex justify-between ">
          {labelInprogress && (
            <div
              className="cursor-pointer"
              onClick={() => navigate(`/${pathInprogress}`)}
            >
              <h3 className="text-sm text-white font-medium">
                {labelInprogress}
              </h3>
              <p className=" text-white font-semibold pt-2">
                {valueInprogress}
              </p>
            </div>
          )}

          {labelVerify && (
            <div
              className="cursor-pointer"
              onClick={() => navigate(`/work-history/${pathVerify}`)}
            >
              <h3 className="text-sm text-white font-medium">{labelVerify}</h3>
              <p className=" text-white font-semibold pt-2">{valueVerify}</p>
            </div>
          )}
          {labelReject && (
            <div
              className="cursor-pointer"
              onClick={() => navigate(`/work-history/${pathReject}`)}
            >
              <h3 className="text-sm text-white font-medium">{labelReject}</h3>
              <p className=" text-white font-semibold pt-2">{valueReject}</p>
            </div>
          )}
           {labelGenerate && (
            <div
              className="cursor-pointer"
              onClick={() => navigate(`/work-history/${pathGenerate}`)}
            >
              <h3 className="text-sm text-white font-medium">{labelGenerate}</h3>
              <p className=" text-white font-semibold pt-2">{valueGenerate}</p>
            </div>
          )}
          {labelDone && (
            <div
              className="cursor-pointer"
              onClick={() => navigate(`/work-history/${pathDone}`)}
            >
              <h3 className="text-sm text-white font-medium">{labelDone}</h3>
              <p className=" text-white font-semibold pt-2">{valueDone}</p>
            </div>
          )}
        </div>
       
      </div>
    );
  };

  return (
    <div className="flex flex-col gap-2 px-4 py-3 h-full">
      <ATMBreadCrumbs breadcrumbs={breadcrumbs} />
      {/* Page Header */}
      <div className="text-xl text-primary-dark font-medium">Dashboard</div>

      <div className="overflow-auto">
        <div className="text-base text-primary-dark font-medium">
          TOTAL APPLICATIONS
        </div>

        <div className="grow ">
          <div className="grid md:grid-cols-3 md:gap-8 gap-4 pt-2 ">
            <InfoCard
              label="PAN APPLICATION"
              className="bg-primary-1"
              value={
                <div>
                  {userType?.type === "SUPER_ADMIN" ? (
                    dashboardData?.PanApplication
                  ) : (
                    <div>
                      {(applicationInProgressCount?.inProgressPanAppliationsCount +
                        applicationStatusCount?.VerifyPanAppliationsCount +
                        applicationStatusCount?.rejectedPanAppliationsCount +
                        applicationStatusCount?.donePanAppliationsCount +
                        applicationStatusCount?.generatedPanAppliationsCount) || 0}
                    </div>
                  )}{" "}
                </div>
              }
              path="history/pan"
              labelSmall="Pending"
              valueSmall={pendingApplicationCount?.totalpanapplications}
              pathSmall="pan/PENDING"
            />
            <InfoCard
              label="ITR APPLICATION"
              className="bg-primary-2"
              value={
                <div>
                  {userType?.type === "SUPER_ADMIN" ? (
                    dashboardData?.ItrApplication
                  ) : (
                    <div>
                      {(applicationInProgressCount?.inProgressItrAppliationsCount +
                        applicationStatusCount?.VerifyItrAppliationsCount +
                        applicationStatusCount?.rejectedItrAppliationsCount +
                        applicationStatusCount?.doneItrAppliationsCount +
                        applicationStatusCount?.generatedItrAppliationsCount) || 0}
                    </div>
                  )}{" "}
                </div>
              }
              path="history/itr"
              labelSmall="Pending"
              valueSmall={pendingApplicationCount?.totalitrapplications}
              pathSmall="itr/PENDING"
            />
            <InfoCard
              label="GUMASTA APPLICATION"
              className="bg-primary-3"
              value={
                <div>
                  {userType?.type === "SUPER_ADMIN" ? (
                    dashboardData?.gumastaApplication
                  ) : (
                    <div>
                      {(applicationInProgressCount?.inProgressGumastaAppliationsCount +
                        applicationStatusCount?.VerifyGumastaAppliationsCount +
                        applicationStatusCount?.rejectedGumastaAppliationsCount +
                        applicationStatusCount?.doneGumastaAppliationsCount +
                        applicationStatusCount?.generatedGumastaAppliationsCount) || 0}
                    </div>
                  )}{" "}
                </div>
              }
              path="history/gumasta"
              labelSmall="Pending"
              valueSmall={pendingApplicationCount?.totalgumastaapplications}
              pathSmall="gumasta/PENDING"
            />
            <InfoCard
              className="bg-primary-4"
              label="DIGITAL SIGNATURE APPLICATION"
              value={
                <div>
                  {userType?.type === "SUPER_ADMIN" ? (
                    dashboardData?.dscApplication
                  ) : (
                    <div>
                      {(applicationInProgressCount?.inProgressDscAppliationsCount +
                        applicationStatusCount?.VerifyDscAppliationsCount +
                        applicationStatusCount?.rejectedDscAppliationsCount +
                        applicationStatusCount?.doneDscAppliationsCount +
                        applicationStatusCount?.generatedDscAppliationsCount) || 0}
                    </div>
                  )}{" "}
                </div>
              }
              path="history/dsc"
              labelSmall="Pending"
              valueSmall={pendingApplicationCount?.totaldscapplications}
              pathSmall="dsc/PENDING"
            />
            <InfoCard
              label="MSME UDHYOG ADHAR APPLICATION"
              className="bg-primary-5"
              value={
                <div>
                  {userType?.type === "SUPER_ADMIN" ? (
                    dashboardData?.msmeApplication
                  ) : (
                    <div>
                      {(applicationInProgressCount?.inProgressMsmeAppliationsCount +
                        applicationStatusCount?.VerifyMsmeAppliationsCount +
                        applicationStatusCount?.rejectedMsmeAppliationsCount +
                        applicationStatusCount?.doneMsmeAppliationsCount +
                        applicationStatusCount?.generatedMsmeAppliationsCount) || 0}
                    </div>
                  )}{" "}
                </div>
              }
              path="history/msme"
              labelSmall="Pending"
              valueSmall={pendingApplicationCount?.totalmsmeapplications}
              pathSmall="msme/PENDING"
            />
            <InfoCard
              label="DIGITAL PAN APPLICATION"
              className="bg-primary-6"
              value={null}
              path="digital-pan"
              labelSmall="Pending"
              valueSmall={0}
              pathSmall="digital-pan"
            />
            {userType?.type === "SUPER_ADMIN" && (
              <InfoCard
                label="DISTRIBUTOR"
                className="bg-primary-7"
                value={userCount?.totalVerifiedDistributor}
                path="distributor"
                labelSmall="Pending"
                valueSmall={userCount?.totalPendingDistributor}
                pathSmall="distributor"
              />
            )}
            {userType?.type === "SUPER_ADMIN" && (
              <InfoCard
                label="RETAILER"
                className="bg-primary-8"
                value={userCount?.totalRetailer}
                path="retailer"
              />
            )}
          </div>
        </div>

        <div className="text-base text-primary-dark font-medium mt-4">
          APPLICATIONS STATUS COUNT
        </div>

        <div className="grow ">
          <div className="grid md:grid-cols-3 md:gap-8 gap-4 pt-2 ">
            <InfoStatusCard
              label="PAN APPLICATION"
              className="bg-primary-1"
              labelInprogress="INPROGRESS"
              valueInprogress={
                applicationInProgressCount?.inProgressPanAppliationsCount
              }
              pathInprogress="pan/IN_PROGRESS"
              labelVerify="VERIFY"
              valueVerify={applicationStatusCount?.VerifyPanAppliationsCount}
              pathVerify="pan?status=VERIFY"
              labelReject="REJECT"
              valueReject={applicationStatusCount?.rejectedPanAppliationsCount}
              pathReject="pan?status=REJECT"
              labelDone="DONE"
              valueDone={applicationStatusCount?.donePanAppliationsCount}
              pathDone="pan?status=DONE"
              labelGenerate="GENERATE" 
              valueGenerate={applicationStatusCount?.generatedPanAppliationsCount}
              pathGenerate="pan?status=GENERATE"
            />
            <InfoStatusCard
              label="ITR APPLICATION"
              className="bg-primary-2"
              pathSmall="itr?status=PENDING"
              labelInprogress="INPROGRESS"
              valueInprogress={
                applicationInProgressCount?.inProgressItrAppliationsCount
              }
              pathInprogress="itr/IN_PROGRESS"
              labelReject="REJECT"
              valueReject={applicationStatusCount?.rejectedItrAppliationsCount}
              pathReject="itr?status=REJECT"
              labelVerify="VERIFY"
              valueVerify={applicationStatusCount?.VerifyItrAppliationsCount}
              pathVerify="itr?status=VERIFY"
              labelDone="DONE"
              valueDone={applicationStatusCount?.doneItrAppliationsCount}
              pathDone="itr?status=DONE"
              labelGenerate="GENERATE" 
              valueGenerate={applicationStatusCount?.generatedItrAppliationsCount}
              pathGenerate="itr?status=GENERATE"
            />
            <InfoStatusCard
              label="GUMASTA APPLICATION"
              className="bg-primary-3"
              labelInprogress="INPROGRESS"
              valueInprogress={
                applicationInProgressCount?.inProgressGumastaAppliationsCount
              }
              pathInprogress="gumasta/IN_PROGRESS"
              labelReject="REJECT"
              valueReject={
                applicationStatusCount?.rejectedGumastaAppliationsCount
              }
              pathReject="gumasta?status=REJECT"
              labelVerify="VERIFY"
              valueVerify={
                applicationStatusCount?.VerifyGumastaAppliationsCount
              }
              pathVerify="gumasta?status=VERIFY"
              labelDone="DONE"
              valueDone={applicationStatusCount?.doneGumastaAppliationsCount}
              pathDone="gumasta?status=DONE"
            />
            <InfoStatusCard
              className="bg-primary-4"
              label="DIGITAL SIGNATURE APPLICATION"
              labelSmall="PENDING"
              pathSmall="dsc?status=PENDING"
              labelInprogress="INPROGRESS"
              valueInprogress={
                applicationInProgressCount?.inProgressDscAppliationsCount
              }
              pathInprogress="dsc/IN_PROGRESS"
              labelReject="REJECT"
              valueReject={applicationStatusCount?.rejectedDscAppliationsCount}
              pathReject="dsc?status=REJECT"
              labelVerify="VERIFY"
              valueVerify={applicationStatusCount?.VerifyDscAppliationsCount}
              pathVerify="dsc?status=VERIFY"
              labelDone="DONE"
              valueDone={applicationStatusCount?.doneDscAppliationsCount}
              pathDone="dsc?status=DONE"
            />

            <InfoStatusCard
              label="MSME UDHYOG ADHAR APPLICATION"
              className="bg-primary-5"
              labelInprogress="INPROGRESS"
              valueInprogress={
                applicationInProgressCount?.inProgressMsmeAppliationsCount
              }
              pathInprogress="msme/IN_PROGRESS"
              labelReject="REJECT"
              valueReject={applicationStatusCount?.rejectedMsmeAppliationsCount}
              pathReject="msme?status=REJECT"
              labelVerify="VERIFY"
              valueVerify={applicationStatusCount?.VerifyMsmeAppliationsCount}
              pathVerify="msme?status=VERIFY"
              labelDone="DONE"
              valueDone={applicationStatusCount?.doneMsmeAppliationsCount}
              pathDone="msme?status=DONE"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardListing;
