import React from "react";
import PANCommission from "./PANCommission";

const PanCommissionWrapper = () => {
  return (
    <>
      {" "}
      <PANCommission />
    </>
  );
};

export default PanCommissionWrapper;
