import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import { Form, Formik, FormikHelpers } from "formik";
import { object, string } from "yup";
import { showToast } from "src/utils/toaster/showToast";
import {  useUpdateUserRefundWalletMutation } from "src/services/RefundBalanceService";
import { useParams } from "react-router-dom";
import UpdateGuestRefundWalletForm from "./UpdateGuestRefundWalletForm";

export type UpdateGuestRefundWalletFormValues = {
  otp: string;
  amountToBeUpdate:string ;
};

type Props = {
  onClose: () => void;
  walletAmountData:string ;
};

const UpdateGuestRefundWalletFormWrapper = ({ onClose , walletAmountData }: Props) => {
  const [updateWallet] = useUpdateUserRefundWalletMutation();
  const [wantToDisabledFiled, setWantToDisabledFiled] = useState(false);

  const { guestId } = useParams();

  // Form Initial Values
  const initialValues: UpdateGuestRefundWalletFormValues = {
    otp: "",
    amountToBeUpdate:walletAmountData
  };

  // Validation Schema
  const validationSchema = object().shape({
    otp: string().required("Please enter otp"),
    amountToBeUpdate: string().required("Please enter amount To BeUpdate"),
  });

  // Handle Submit
  const handleSubmit = (
    values: UpdateGuestRefundWalletFormValues,
    { setSubmitting, resetForm }: FormikHelpers<UpdateGuestRefundWalletFormValues>
  ) => { 
 
    const formattedValues = {
        otp: values?.otp,
        amountToBeUpdate:values?.amountToBeUpdate ,
        userId: guestId,
    }

    updateWallet(formattedValues).then((res: any) => {
      if (res?.error) {
        showToast("error", res?.error?.data?.message);
      } else {
        if (res?.data?.status) {
          showToast("success", res?.data?.message);
          setWantToDisabledFiled(false)
          resetForm();
          onClose();
        } else {
          showToast("error", res?.data?.message);
        }
      }
      setSubmitting(false);
    });
  };

  return (
    <Dialog open maxWidth="xs" fullWidth>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formikProps) => (
          <Form>
            <UpdateGuestRefundWalletForm
             setWantToDisabledFiled={setWantToDisabledFiled}
             wantToDisabledFiled={wantToDisabledFiled}
              formikProps={formikProps}
              onClose={onClose}
            />
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default UpdateGuestRefundWalletFormWrapper;
