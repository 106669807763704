import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface SearchHistorySliceStateType {
  panSearchHistoryValue: string;
  itrSearchHistoryValue: string;
  dscSearchHistoryValue: string;
  gumastaSearchHistoryValue: string;
  msmeSearchHistoryValue: string;
}

const initialState: SearchHistorySliceStateType = {
  panSearchHistoryValue: "",
  itrSearchHistoryValue: "",
  dscSearchHistoryValue: "",
  gumastaSearchHistoryValue: "",
  msmeSearchHistoryValue: "",
};

const searchHistorySlice: any = createSlice({
  name: "searchHistorySlice",
  initialState,
  reducers: {
    setPanSearchHistoryValue: (state, action: PayloadAction<string>) => {
      state.panSearchHistoryValue = action.payload;
    },
    setItrSearchHistoryValue: (state, action: PayloadAction<string>) => {
      state.itrSearchHistoryValue = action.payload;
    },
    setDscSearchHistoryValue: (state, action: PayloadAction<string>) => {
      state.dscSearchHistoryValue = action.payload;
    },
    setGumastaSearchHistoryValue: (state, action: PayloadAction<string>) => {
      state.gumastaSearchHistoryValue = action.payload;
    },
    setMsmeSearchHistoryValue: (state, action: PayloadAction<string>) => {
      state.msmeSearchHistoryValue = action.payload;
    },
  },
});

export const {
  setDscSearchHistoryValue,
  setGumastaSearchHistoryValue,
  setItrSearchHistoryValue,
  setMsmeSearchHistoryValue,
  setPanSearchHistoryValue,
} = searchHistorySlice.actions;
export default searchHistorySlice.reducer;
