import apiSlice from "./ApiSlice";

export const searchHistoryApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    //PAN
    getPanApplicationSearchBySRN: builder.query({
      providesTags: ["static-pages"],
      query: (srn) => ({
        url: `/pan-app/admin/view-with-srn?srn=${srn}`,
        method: "GET",
      }),
    }),

    //ITR
    getItrApplicationSearchBySRN: builder.query({
      providesTags: ["static-pages"],
      query: (srn) => ({
        url: `/itr-application/admin/view-with-srn?srn=${srn}`,
        method: "GET",
      }),
    }),

    //GUMASTA
    getGumastaApplicationSearchBySRN: builder.query({
      providesTags: ["static-pages"],
      query: (srn) => ({
        url: `/gumasta-application/admin/view-with-srn?srn=${srn}`,
        method: "GET",
      }),
    }),
    //DSC
    getDscApplicationSearchBySRN: builder.query({
      providesTags: ["static-pages"],
      query: (srn) => ({
        url: `/dsc-application/admin/view-with-srn?srn=${srn}`,
        method: "GET",
      }),
    }),

    //MSME
    getMsmeApplicationSearchBySRN: builder.query({
      providesTags: ["static-pages"],
      query: (srn) => ({
        url: `/msme-application/admin/view-with-srn?srn=${srn}`,
        method: "GET",
      }),
    }),
  }),
});
export const {
  useGetPanApplicationSearchBySRNQuery,
  useGetDscApplicationSearchBySRNQuery,
  useGetItrApplicationSearchBySRNQuery,
  useGetGumastaApplicationSearchBySRNQuery,
  useGetMsmeApplicationSearchBySRNQuery
} = searchHistoryApi;
