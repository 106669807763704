import React from 'react'
import GumastaConfiguration from './GumastaConfiguration'
import SideNavLayout from 'src/components/layouts/SideNavLayout/SideNavLayout'

const GumastaConfigurationWrapper = () => {
  return (
    <SideNavLayout>
<GumastaConfiguration/>
    </SideNavLayout>
  
  )
}

export default GumastaConfigurationWrapper